(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/config/menu-config/views/user-menu-header.js') >= 0) return;  svs.modules.push('/components/config/menu-config/views/user-menu-header.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.config=_svs.config||{};
_svs.config.menu_config=_svs.config.menu_config||{};
_svs.config.menu_config.templates=_svs.config.menu_config.templates||{};
svs.config.menu_config.templates.user_menu_header = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"navigation-user-header\">\n  <div class=\"navigation-user-divider\"><img alt=\"\"\n      src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/components/ao-logotype-soc/ao-soc.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":74}}}))
    + "\"></div>\n  <div class=\"navigation-user-header-name js-logged-in padding-xs-h-2 margin-xs-bottom-1\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":4,"column":90},"end":{"line":4,"column":103}}}) : helper)))
    + "</div>\n  <div class=\"grid-row padding-xs-h-2\">\n    <div class=\"col-xs-6 padding-xs-v-1\">\n      <i class=\"icon fc-sport icon-200 icon-clock-simple margin-xs-right\"></i>\n      <span class=\"js-session-time-header align-middle\">0 min</span>\n    </div>\n    <div class=\"js-account-balance-toggle navigation-user-account-balance-toggle pointer col-xs-6 padding-xs-v-1\">\n      <i class=\"icon fc-sport icon-200 icon-balance margin-xs-right\"></i>\n      <span class=\"js-account-balance align-middle\">Visa saldo</span>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-config-menu_config-user_menu_header'] = svs.config.menu_config.templates.user_menu_header;
})(svs, Handlebars);


 })(window);