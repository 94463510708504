(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/config/menu-config/assets/javascripts/navigation-menu.js') >= 0) return;  svs.modules.push('/components/config/menu-config/assets/javascripts/navigation-menu.js');

'use strict';

const loginAndCreateUserLinks = document.querySelectorAll('.nav-menu-header-button, .navigation-user-logged-out a');
for (let i = 0, length = loginAndCreateUserLinks.length; i < length; i++) {
  const link = loginAndCreateUserLinks[i];
  link.addEventListener('click', e => {
    var _target$dataset, _target$dataset2;
    e.preventDefault();
    const target = e.currentTarget;
    svs.components.analytics.trackEvent({
      category: 'right menu',
      action: (_target$dataset = target.dataset) === null || _target$dataset === void 0 ? void 0 : _target$dataset.track,
      opt_label: (_target$dataset2 = target.dataset) === null || _target$dataset2 === void 0 ? void 0 : _target$dataset2.track
    }, () => {
      location.href = "".concat(target.getAttribute('href'), "?returnUrl=").concat(encodeURIComponent(svs.utils.url.makeInternal(location.pathname + location.search || '/')));
    });
  });
}

const logger = new svs.core.log.Logger('config:menu-config');
function startDeposit() {
  const depositValues = {
    amount: 50,
    skipStepOne: false
  };
  const storageTimeInMinutes = 30;
  svs.components.Storage.browser.set('depositValues', 'quick-deposit', depositValues, storageTimeInMinutes, error => {
    if (error) {
      logger.warn("Failed to set depositValues: ".concat(error));
    }
  });
}
const depositPlayBtn = document.querySelector('.js-navigation-user-deposit-play');
depositPlayBtn && depositPlayBtn.addEventListener('click', () => {
  const loginUrl = svs.core.urlMapping.get('loginHome');
  const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  startDeposit();
  const target = isLoggedIn ? svs.core.urlMapping.get('quickDeposit') : loginUrl;
  svs.utils.url.navigate(target);
});

const newSportsbook = svs.core.detect.feature('ft-new-sportsbook');
if (newSportsbook) {
  const navigationMenuOpen = document.querySelector('.js-nav-user');
  const historyBadge = document.querySelector('.js-account-history-badge');
  let hasCheked = false;
  navigationMenuOpen && navigationMenuOpen.addEventListener('click', async () => {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      return;
    }
    if (hasCheked) {
      return;
    }
    if (!historyBadge) {
      logger.warn('Right navigation event listner: History badge not found');
      return;
    }
    const options = {
      method: 'GET',
      path: '/player/1/balance'
    };
    try {
      const {
        response
      } = await svs.core.jupiter.callAsync(options);
      if (response.error) {
        throw new Error(JSON.stringify(response.error));
      }
      const validResponse = response && response.accountDebt;
      if (validResponse && Number(response.accountDebt.replace(',', '.')) > 0.01) {
        historyBadge.classList.remove('hide');
      }
      hasCheked = true;
    } catch (err) {
      err.message = decodeURIComponent(err === null || err === void 0 ? void 0 : err.message);
      ((err === null || err === void 0 ? void 0 : err.code) && err.code !== 666 || !(err !== null && err !== void 0 && err.code)) && logger.error("Failure to retrive account balance: ".concat(JSON.stringify(err)));
    }
  });
}

 })(window);